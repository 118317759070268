<template>
  <div class="ud-body ud-body-card">
    <a-row :gutter="16">
      <a-col :lg="6" :md="10" :sm="24" :xs="24">
        <a-card
          :bordered="false"
          :body-style="{padding: '24px 16px'}">
          <!-- 表格 -->
          <ud-pro-table
            ref="table"
            row-key="dictId"
            :datasource="url"
            :columns="columns"
            v-model:current="current"
            :need-page="false"
            :row-selection="{columnWidth: 38}"
            :toolkit="[]"
            @done="done">
            <template #toolbar>
              <a-space size="middle">
                <a-button
                  type="primary"
                  @click="openEdit()">新建
                </a-button>
                <a-button
                  type="primary"
                  @click="openEdit(current)"
                  :disabled="!current">修改
                </a-button>
                <a-button
                  danger
                  type="primary"
                  @click="remove"
                  :disabled="!current">删除
                </a-button>
              </a-space>
            </template>
          </ud-pro-table>
        </a-card>
      </a-col>
      <a-col :lg="18" :md="14" :sm="24" :xs="24">
        <a-card :bordered="false">
          <sys-dict-data
            v-if="current"
            :dict-id="current.dictId"/>
        </a-card>
      </a-col>
    </a-row>
  </div>
  <!-- 编辑弹窗 -->
  <sys-dict-edit
    v-model:visible="showEdit"
    :data="editData"
    @done="reload"/>
</template>

<script>
import { createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import SysDictData from './sys-dict-data'
import SysDictEdit from './sys-dict-edit'

export default {
  name: 'SystemDictionary',
  components: { SysDictData, SysDictEdit },
  data() {
    return {
      // 表格数据接口
      url: '/sys/dict',
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 38,
          customRender: ({ index }) => index + 1
        },
        {
          title: '字典名称',
          dataIndex: 'dictName'
        }
      ],
      // 表格选中数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,
      // 编辑回显数据
      editData: null
    }
  },
  methods: {
    /* 表格渲染完成回调 */
    done(res) {
      if (res.data.length > 0) {
        this.current = res.data[0]
      }
    },
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload()
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.editData = row
      this.showEdit = true
    },
    /* 删除 */
    remove() {
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的字典吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          this.$http.delete('/sys/dict/' + this.current.dictId).then(res => {
            hide()
            if (res.data.code === 0) {
              this.$message.success(res.data.msg)
              this.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            hide()
            this.$message.error(e.message)
          })
        }
      })
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .ant-card {
    min-height: calc(100vh - 122px);
  }
}
</style>
